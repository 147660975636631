
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component({})
export default class PortfolioItemCard extends Vue {
  // Title of portfolio item
  @Prop() readonly titleString!: string

  // Link to portfolio item repository
  @Prop() readonly githubURL!: string

  // Link to paths of screenshots of the portfolio item
  @Prop() readonly imagePaths!: string[]

  // Description of portfolio item; each string is a paragraph
  @Prop() readonly description!: string[]

  // Whether to place the images on the left side
  @Prop() readonly left!: boolean

  // Dates the item was worked on
  @Prop() readonly date!: string

  // Languages and frameworks used for the portfolio item
  @Prop() readonly languages!: string

  // Big challenges in the portfolio item
  @Prop() readonly challenges!: string

  // List of fellow collaborators on this project
  @Prop() readonly collaborators!: string

  // URL of an iFrame to be used to demonstrate the item
  @Prop() readonly iframeURL!: string

  // Title of iFrame window in previews
  @Prop() readonly iframeTitle!: string

  // Note to place below iFrame for a brief description
  @Prop() readonly iframeNote!: string[]

  // Width of iFrame
  @Prop() readonly iframeWidth!: string

  // Height of iFrame
  @Prop() readonly iframeHeight!: string

  // Size of iFrame Modal
  @Prop() readonly iframeModalSize!: string

  // Set to true if this is a non-item, and is instead an about me section
  @Prop() readonly aboutMe!: boolean

  // External link --> if iFrame provided, links to the iFrame version's repo
  // If no iFrame provided, links to an external site
  @Prop() readonly externalLink!: string

  // Preview type; makes it clear what each link does
  @Prop() readonly previewType!: string
}

