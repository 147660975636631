
import { Component, Vue } from 'vue-property-decorator';
import PortfolioItemCard from '@/components/PortfolioItemCard.vue';
import ContactComponent from '@/components/ContactComponent.vue';
/**
 * Home Page View
 */
@Component({ components: { ContactComponent, PortfolioItemCard } })
export default class HomeView extends Vue {
}
