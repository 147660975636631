
import {
  Prop, Component, Vue,
} from 'vue-property-decorator';

/**
 * This component is simply a button with a title + icon with an external link
 * I noticed a little too much duplicated code so I made a separate component to hold it
 */
@Component({})
export default class ContactComponent extends Vue {
  @Prop() readonly titleString! : string

  @Prop() readonly destinationURL! : string

  @Prop() readonly iconComponent!: string
}
